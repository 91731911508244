import { useState } from "react";  // Удаляем useContext, так как он больше не нужен
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";  // Добавляем useDispatch для использования Redux
import { registerUser } from "../slices/authSlice";  // Импортируем экшен для регистрации
import { FormControl, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Registerpage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [holding_name, setHoldingName] = useState("");
  const [tel, setTel] = useState("");

  const dispatch = useDispatch();  // Инициализируем dispatch
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(registerUser({ email, password, name, surname, holding_name, tel })).then((result) => {
      if (result.meta.requestStatus === 'fulfilled') {
        history.push("/login");; // Перенаправляем после успешного входа
      }
    });
  };

  // Создание темы с переопределенным свойством MuiInputBase
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "30px", // Установите высоту по вашему усмотрению
            width: "90%",
            marginBottom: "20px",
          },
          input: {
            // Стили для полной отмены эффекта автозаполнения
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset", // Прозрачный фон
              WebkitTextFillColor: "inherit", // Цвет текста как у родителя
              transition: "background-color 5000s ease-in-out 0s", // Убираем анимацию
            },
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{height:'85vh'}}
    >
      <FormControl component="form" onSubmit={handleSubmit}>
        <Typography variant="h1" align="center">
          Регистрация
        </Typography>
        <Grid container>
          <Grid item md={6}>
            <Typography>Введите ваше имя:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="name"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>Введите ваш email:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="email"
                type="email"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>Введите вашу фамилию:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="surname"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setSurname(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>Введите ваш пароль:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="password"
                type="password"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>Введите название вашего СМИ или Холдинга:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="holding_name"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setHoldingName(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>Введите ваш номер телефона:</Typography>
            <ThemeProvider theme={theme}>
              <TextField
                name="tel"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setTel(e.target.value)}
                fullWidth
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={6}>
            <Typography>&nbsp;</Typography>
            <Button variant="contained" color="primary" type="submit">
              Зарегистрироваться
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

export default Registerpage;
