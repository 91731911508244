import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import { Button, FileUpload } from "@mui/material";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import QuoteTableWe from "../QuoteTableWe";
import QuoteTableUs from "../QuoteTableUs";
import ExclusivesTable from "../ExclusivesTable";
import {
  fetchSiteIdBySlug,
  setDateRange,
  updateSchedule,
  fetchSchedule,
  fetchScheduleByPeriod,
  fetchScheduleUS,
  fetchScheduleUSByPeriod,
  fetchAllExclusives,
  fetchAllExclusivesByPeriod,
  fetchMyExclusives,
  fetchMyExclusivesByPeriod,
  downloadExclusiveFile,
  downloadExclusiveFileByPeriod,
  downloadAllExclusiveFile,
  downloadAllExclusiveFileByPeriod,
  downloadSheduleFile,
  downloadSheduleFileByPeriod,
  downloadSheduleUsFile,
  downloadSheduleUsFileByPeriod,
  uploadSheduleFile,
  uploadSheduleUsFile,
  uploadExclusivesSitesFile,
} from "../../slices/siteSlice";
import { getCurrentWeekDates } from "../../utils/dateUtils";

function Shedule() {
  const dispatch = useDispatch();
  const {
    selectedSite,
    siteId,
    scheduleWe,
    scheduleUs,
    startDate,
    endDate,
    exclusives,
    loadingScheduleWe,
    loadingScheduleUs,
    loadingExclusives,
  } = useSelector((state) => state.site);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchSiteIdBySlug(selectedSite));
  }, [selectedSite]);

  const isWeActive = location.pathname === `/site/${selectedSite}/shedule_we`;
  const isUsActive = location.pathname === `/site/${selectedSite}/shedule_us`;

  const [onlyMyExclusives, setOnlyMyExclusives] = useState(false);
  const [linkToEdit, setLinkToEdit] = useState({ id: null, url: "" });
  const [dateRange, setDateRangeState] = useState(getCurrentWeekDates());
  const [isApplyButtonActive, setApplyButtonActive] = useState(false);

  useEffect(() => {
    const [tempStartDate, tempEndDate] = dateRange || [null, null];
    if ( tempStartDate, tempEndDate ) {
      const newStartDate = tempStartDate.toISOString().split("T")[0]
      const newEndDate = tempEndDate.toISOString().split("T")[0]
      dispatch(setDateRange([newStartDate, newEndDate]));
    }
  }, []);

  const fetchExclusives = () => {
    if (onlyMyExclusives) {
      if (startDate && endDate) {
        dispatch(fetchMyExclusivesByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(fetchMyExclusives(siteId));
      }
    } else {
      if (startDate && endDate) {
        dispatch(fetchAllExclusivesByPeriod({ startDate, endDate }));
      } else {
        dispatch(fetchAllExclusives());
      }
    }
  };

  const fetchCitations = () => {
    if (isWeActive && siteId) {
      if (startDate && endDate) {
        dispatch(fetchScheduleByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(fetchSchedule(siteId));
      }
    } else if (isUsActive && siteId) {
      if (startDate && endDate) {
        dispatch(fetchScheduleUSByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(fetchScheduleUS(siteId));
      }
    }
  };

  const handleUploadCitations = async (event) => {
    const file = event.target.files[0];

    if (file && siteId) {
      // Вызов загрузки файла в зависимости от флага
      if (isWeActive) {
        dispatch(uploadSheduleFile({ siteId, file }));
      } else {
        dispatch(uploadSheduleUsFile({ siteId, file }));
      }

      // Сбрасываем значение поля файла, чтобы обеспечить повторный вызов
      event.target.value = null;
    }
  };

  const handleUploadExclusives = async (event) => {
    const file = event.target.files[0];

    if (file && siteId) {
      dispatch(uploadExclusivesSitesFile({ siteId, file }));
      // Сбрасываем значение поля файла, чтобы обеспечить повторный вызов
      event.target.value = null;
    }
  };

  useEffect(() => {
    fetchExclusives();
    fetchCitations();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchExclusives();
  }, [onlyMyExclusives]);

  const handleCheckboxChange = (event) => {
    setOnlyMyExclusives(event.target.checked);
  };

  useEffect(() => {
    if (siteId && startDate && endDate) {
      fetchCitations();
    }
  }, [siteId, startDate, endDate]);

  const handleDateChange = (newDateRange) => {
    setDateRangeState(newDateRange);
    setApplyButtonActive(true);
    const [tempStartDate, tempEndDate] = newDateRange || [null, null];
    if ( tempStartDate, tempEndDate ) {
      const newStartDate = tempStartDate.toISOString().split("T")[0]
      const newEndDate = tempEndDate.toISOString().split("T")[0]
      dispatch(setDateRange([newStartDate, newEndDate]));
    }
  };


  const handleBlur = async () => {
    if (linkToEdit.id) {
      await dispatch(
        updateSchedule({
          user_material_id: linkToEdit.id,
          data: { url: linkToEdit.url },
        })
      );
    }
  };

  const handleDownloadExclusiveClick = () => {
    if (onlyMyExclusives) {
      if (startDate && endDate) {
        dispatch(downloadExclusiveFileByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(downloadExclusiveFile(siteId));
      }
    } else {
      if (startDate && endDate) {
        dispatch(downloadAllExclusiveFileByPeriod({ startDate, endDate }));
      } else {
        dispatch(downloadAllExclusiveFile());
      }
    }
  };

  const handleDownloadCitationsClick = () => {
    if (isWeActive) {
      if (startDate && endDate) {
        dispatch(downloadSheduleFileByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(downloadSheduleFile(siteId));
      }
    } else if (isUsActive) {
      if (startDate && endDate) {
        dispatch(downloadSheduleUsFileByPeriod({ siteId, startDate, endDate }));
      } else {
        dispatch(downloadSheduleUsFile(siteId));
      }
    }
  };

  const pickerStyle = {
    marginBottom: "20px",
    marginTop: "10px",
    marginRight: "10px",
  };

  const cellStyle = {
    padding: "4px",
    height: "50px",
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">Расписание</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ display: "flex" }}>
          <Typography>
            <Link
              // className="colorPrimary"
              to={`/site/${selectedSite}/shedule_we`}
              style={{ color: isWeActive ? "red" : "black" }}
            >
              Цитируем мы
            </Link>
          </Typography>
          <Typography>&nbsp; / &nbsp;</Typography>
          <Typography>
            <Link
              to={`/site/${selectedSite}/shedule_us`}
              style={{ color: isUsActive ? "red" : "black" }}
            >
              Цитируют нас
            </Link>
          </Typography>
        </div>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <DateRangePicker
              style={pickerStyle}
              value={dateRange}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ display: "flex" }}></div>
          <Grid container alignItems="center" style={{ marginTop: "10px" }}>
            <Typography style={{ marginRight: "10px" }}>
              Только мои эксклюзивы
            </Typography>
            <Checkbox
              checked={onlyMyExclusives}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "Показать только мои эксклюзивы" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
        {/* Расписание */}
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: "10px", marginBottom: "10px" }}
            onClick={handleDownloadCitationsClick}
          >
            Скачать
          </Button>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            style={{ marginRight: "10px", marginBottom: "10px" }}
          >
            Загрузить
            <input type="file" hidden onChange={handleUploadCitations} />
          </Button>
        </Grid>
        <Grid item> 
        {/* Эксклюзивы */}
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: "10px", marginBottom: "10px" }}
            onClick={handleDownloadExclusiveClick}
          >
            Скачать
          </Button>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            style={{ marginRight: "10px", marginBottom: "10px" }}
          >
            Загрузить
            <input type="file" hidden onChange={handleUploadExclusives} />
          </Button>
        </Grid>
      </Grid>

      {/* Контейнер для таблиц */}
      <Grid container spacing={1} style={{ marginTop: "10px" }}>
        {" "}
        {/* Уменьшение расстояния между колонками */}
        <Grid item xs={7}>
          {" "}
          {/* Изменение соотношения ширины */}
          <Typography
            variant="h6"
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Расписание
          </Typography>
          {
            <>
              {loadingScheduleWe ? (
                <div className="preloader">Загрузка...</div>
              ) : (
                <>
                  {isWeActive && (
                    <QuoteTableWe
                      data={scheduleWe}
                      cellStyle={cellStyle}
                      onLinkEdit={setLinkToEdit}
                      linkToEdit={linkToEdit}
                      onLinkBlur={handleBlur}
                    />
                  )}
                </>
              )}
              {loadingScheduleUs ? (
                <div className="preloader">Загрузка...</div>
              ) : (
                <>
                  {isUsActive && (
                    <QuoteTableUs data={scheduleUs} cellStyle={cellStyle} />
                  )}
                </>
              )}
            </>
          }
        </Grid>
        <Grid item xs={5}>
          {" "}
          {/* Изменение соотношения ширины */}
          <Typography
            variant="h6"
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Эксклюзивы
          </Typography>
          {loadingExclusives ? (
            <div className="preloader">Загрузка...</div>
          ) : (
            <ExclusivesTable data={exclusives} cellStyle={cellStyle} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Shedule;
