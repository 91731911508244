import { Grid, TableCell, Typography } from "@mui/material";
import { FormControl, TextField, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import useAxios from "../../utils/useAxios";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const HoldingeSettings = () => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user.user_id;
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Открытие и закрытие модального окна
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Обработчик нажатия на кнопку "Сохранить"
  const handleSave = async () => {
    if (password === confirmPassword) {
      try {
        // поменять profile.user_id
        // const response = await axiosInstance.put(`/UpdatePassword/${profile.user_id}`, { 
        const response = await axiosInstance.put(`/UpdatePassword/${user_id}`, { 
          password: password,
          confirm_password: confirmPassword,
        });
        console.log("Пароль успешно обновлен", response.data);
        handleClose(); // Закрываем окно после успешного сохранения
      } catch (error) {
        console.error("Ошибка при обновлении пароля:", error);
      }
    } else {
      console.log("Пароли не совпадают");
    }
  };
  const axiosInstance = useAxios();
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [tel, setTel] = useState("");
  const [holdingName, setHoldingName] = useState("");
  const [newSiteName, setNewSiteName] = useState("");
  const [sites, setSites] = useState([]);
  const [managers, setManagers] = useState([]);
  const [newManager, setNewManager] = useState("");
  const [newManagerPassword, setNewManagerPassword] = useState("");
  const [accountStatus, setAccountStatus] = useState("");

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get("/api/holding-settings/");
      setProfile(response.data);
      setName(response.data.name);
      setSurname(response.data.surname);
      setTel(response.data.tel);
      setHoldingName(response.data.holding_name);
      setSites(response.data.sites_name);
      setManagers(response.data.managers_id);
      setAccountStatus(response.data.account_status);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await axiosInstance.put("/api/holding-settings/", {
        name,
        surname,
        tel,
        holding_name: holdingName,
      });
      console.log(response.data);
      fetchUserProfile();
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const handleDeleteSite = async (siteId) => {
    try {
      await axiosInstance.delete(`/delete-site/${siteId}/`);
      fetchUserProfile();
    } catch (error) {
      console.error("Error deleting site:", error);
    }
  };

  const handleCreateSite = async () => {
    try {
      await axiosInstance.post("/create-site/", {
        site_name: newSiteName,
      });
      fetchUserProfile();
      setNewSiteName("");
    } catch (error) {
      console.error("Error creating site:", error);
    }
  };
  const handleCreateManager = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(newManager)) {
        console.error("Некорректный адрес электронной почты");
        alert("Введите корректный адрес электронной почты.");
        return;
    }

    try {
      await axiosInstance.post("/create-manager/", {
        manager_email: newManager,
        manager_password: newManagerPassword,
      });
      fetchUserProfile();
      setNewManager("");
      newManagerPassword("");
      window.location.reload(); // Обновить страницу после успешного создания менеджера
    } catch (error) {
      console.error("Error creating manager:", error);
    }
  };

  const handleDeleteManager = async (managerId) => {
    try {
      await axiosInstance.delete(`/api/delete-manages/${managerId}/`);
      fetchUserProfile();
    } catch (error) {
      console.error("Error deleting manager:", error);
    }
  };

  const handleSiteChange = async (e, index, field) => {
    const updatedSites = [...sites];

    if (typeof updatedSites[index][field] === "number" && e.target.value < 0) {
      return;
    }

    if (typeof updatedSites[index][field] === "boolean") {
      updatedSites[index][field] = e.target.checked;
    } else {
      updatedSites[index][field] = e.target.value;
    }
    if (field === "transfer_ic") {
      if (Array.isArray(updatedSites[index][field])) {
        let result = "";
        for (let i = 0; i < updatedSites[index][field].length; i++) {
          if (updatedSites[index][field][i] !== "") {
            result += updatedSites[index][field][i];
            if (
              i < updatedSites[index][field].length - 1 &&
              updatedSites[index][field][i + 1] !== ""
            ) {
              result += ",";
            }
          }
        }
        updatedSites[index][field] = result;
      }
    }

    try {
      const siteId = updatedSites[index].sites_id;
      await axiosInstance.put(`/api/sites/${siteId}/`, updatedSites[index]);
      console.log("Site updated successfully!");
      fetchUserProfile();
    } catch (error) {
      console.error("Error updating site:", error);
    }

    setSites(updatedSites);
  };

  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "39px", // Установите высоту по вашему усмотрению
          },
        },
      },
    },
  });
  const theme_2 = createTheme({
    palette: {
      primary: {
        main: "#EF273D",
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "5px",
            textAlign: "center",
            fontSize: "13px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "30px", // Установите высоту по вашему усмотрению
            width: "90%",
          },
        },
      },
    },
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  return (
    <>
      {profile ? (
        <>
          {" "}
          <Typography variant="h1">Настройки представления: Холдинг</Typography>
          <Typography variant="h2">Профайл аккаунта:</Typography>
          <Grid container justifyContent="space-between">
            <Grid item md={6}>
              <Typography>Ваше имя:</Typography>
              <ThemeProvider theme={theme}>
                <TextField
                  name={name}
                  id="outlined-basic"
                  variant="outlined"
                  style={{ paddingBottom: "20px" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </ThemeProvider>
            </Grid>
            <Grid item md={5}>
              <Typography>Ваш Email:</Typography>
              <ThemeProvider theme={theme}>
                <TextField
                  name={name}
                  id="outlined-basic"
                  variant="outlined"
                  value={profile.email}
                  style={{ paddingBottom: "20px" }}
                  fullWidth
                />
              </ThemeProvider>
            </Grid>
            <Grid item md={6}>
              <Typography>Ваша фамилия:</Typography>
              <ThemeProvider theme={theme}>
                <TextField
                  name={surname}
                  id="outlined-basic"
                  variant="outlined"
                  style={{ paddingBottom: "20px" }}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  fullWidth
                />
              </ThemeProvider>
            </Grid>
            <Grid item md={5}>
              <Typography>Восстановление пароля:</Typography>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Сменить пароль
              </Button>
            </Grid>
            <Grid item md={6}>
              <Typography>Название холдинга:</Typography>
              <ThemeProvider theme={theme}>
                <TextField
                  name={holdingName}
                  id="outlined-basic"
                  variant="outlined"
                  value={holdingName}
                  style={{ paddingBottom: "20px" }}
                  onChange={(e) => setHoldingName(e.target.value)}
                  fullWidth
                />
              </ThemeProvider>
            </Grid>
            <Grid item md={5}>
              <Typography>Ваш номер телефона:</Typography>
              <ThemeProvider theme={theme}>
                <TextField
                  name={tel}
                  id="outlined-basic"
                  variant="outlined"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  style={{ paddingBottom: "20px" }}
                  fullWidth
                />
              </ThemeProvider>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateProfile}
              >
                Обновить профайл
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={6}>
              <Typography variant="h2">Сайты:</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="h2">Менеджеры:</Typography>
            </Grid>
            <Grid item md={6}>
              <ThemeProvider theme={theme_2}>
                <TableContainer style={{ height: 200 }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Сайт <br /> &nbsp;
                        </TableCell>
                        <TableCell align="center">
                          Принимаю <br /> &nbsp;
                        </TableCell>
                        <TableCell align="center">
                          Перенаправление цитирования
                        </TableCell>
                        <TableCell align="center">
                          Отдаю <br /> &nbsp;
                        </TableCell>
                        <TableCell align="center">
                          Материалы в неделю <br /> &nbsp;
                        </TableCell>
                        <TableCell align="center">
                          Удалить <br /> &nbsp;
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sites.map((site, index) => (
                        <TableRow key={site.sites_id}>
                          <TableCell>
                            <Typography>{site.site_name}</Typography>
                          </TableCell>

                          <TableCell>
                            <Checkbox
                              checked={site.can_get}
                              onChange={(e) =>
                                handleSiteChange(e, index, "can_get")
                              }
                            />
                          </TableCell>

                          <TableCell>
                            <Select
                              multiple
                              value={
                                Array.isArray(site.transfer_ic)
                                  ? site.transfer_ic
                                  : site.transfer_ic.split(",")
                              }
                              onChange={(e) =>
                                handleSiteChange(e, index, "transfer_ic")
                              }
                              input={<OutlinedInput label="Transfer" />}
                              MenuProps={MenuProps}
                              style={{ maxWidth: "150px" }}
                            >
                              {sites.map((site, index) => (
                                <MenuItem
                                  key={site.name}
                                  value={site.site_name}
                                >
                                  {site.site_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>

                          <TableCell>
                            <Checkbox
                              checked={site.can_put}
                              onChange={(e) =>
                                handleSiteChange(e, index, "can_put")
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <ThemeProvider theme={theme}>
                              <TextField
                                name="m_num"
                                id="outlined-basic"
                                variant="outlined"
                                type="number"
                                value={site.numbersMaterials}
                                onChange={(e) =>
                                  handleSiteChange(e, index, "numbersMaterials")
                                }
                                fullWidth
                              />
                            </ThemeProvider>
                          </TableCell>

                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDeleteSite(site.sites_id)}
                            >
                              <DeleteForeverIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ThemeProvider>
            </Grid>
            <Grid item md={5}>
              <ThemeProvider theme={theme_2}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Email <br /> &nbsp;
                        </TableCell>
                        <TableCell align="right">
                          Удалить <br /> &nbsp;
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {managers.map((manager, index) => (
                        <TableRow>
                          <TableCell>
                            <Typography>{manager.manager_email}</Typography>
                          </TableCell>

                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDeleteManager(manager.id)}
                            >
                              <DeleteForeverIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ThemeProvider>
            </Grid>
          </Grid>
          <hr style={{ marginTop: "30px", opacity: "0.2" }}></hr>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={6}>
              <Typography variant="h2">Добавить сайт:</Typography>
              <>
                <ThemeProvider theme={theme}>
                  <TextField
                    type="text"
                    placeholder="Site Name"
                    value={newSiteName}
                    onChange={(e) => setNewSiteName(e.target.value)}
                    style={{ paddingBottom: "20px" }}
                  />
                </ThemeProvider>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateSite}
                >
                  <AddIcon />
                </Button>
              </>
            </Grid>
            <Grid item md={5}>
              <Typography variant="h2">Добавить менеджера:</Typography>
              <>
                <ThemeProvider theme={theme}>
                  <TextField
                    type="text"
                    placeholder="Email"
                    value={newManager}
                    onChange={(e) => setNewManager(e.target.value)}
                    style={{ paddingBottom: "20px", width: "180px" }}
                  />
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <TextField
                    type="text"
                    placeholder="Пароль"
                    value={newManagerPassword}
                    onChange={(e) => setNewManagerPassword(e.target.value)}
                    style={{ paddingBottom: "20px", width: "150px" }}
                  />
                </ThemeProvider>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateManager}
                >
                  <AddIcon />
                </Button>
              </>
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Сменить пароль</DialogTitle>
            <DialogContent>
              <TextField
                label="Введите пароль"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                label="Введите пароль повторно"
                type="password"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Отмена
              </Button>
              <Button onClick={handleSave} color="primary" variant="contained">
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default HoldingeSettings;
