import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormControl, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loginUser } from "../slices/authSlice";
import { clearSelectedSite } from "../slices/siteSlice";

function Loginpage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearSelectedSite());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (email && password) {
      dispatch(loginUser({ email, password })).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          history.push("/"); // Перенаправляем после успешного входа
        }
      });
    }
  };

  // Создание темы с переопределенными стилями для MuiInputBase и -webkit-autofill
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "30px",
            marginBottom: "20px",
          },
          input: {
            // Стили для полной отмены эффекта автозаполнения
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset", // Прозрачный фон
              WebkitTextFillColor: "inherit", // Цвет текста как у родителя
              transition: "background-color 5000s ease-in-out 0s", // Убираем анимацию
            },
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "85vh" }}
    >
      <FormControl component="form" onSubmit={handleSubmit}>
        <Typography variant="h1" align="center">
          Вход в систему
        </Typography>
        <Typography>Введите ваш Email:</Typography>
        <ThemeProvider theme={theme}>
          <TextField name="email" type="email" />
        </ThemeProvider>
        <Typography>Пароль:</Typography>
        <ThemeProvider theme={theme}>
          <TextField name="password" type="password" />
        </ThemeProvider>
        <Typography component={Link} to="/password_reset">Забыли пароль?</Typography>
        <Button component={Link} to="/register">
          Регистрация
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          Вход
        </Button>
      </FormControl>
    </Grid>
  );
}

export default Loginpage;
