import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormControl, TextField, Button, Typography, Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function PassReset() {
  const [isSubmitted, setIsSubmitted] = useState(false); // Отслеживаем отправку формы
  const [timer, setTimer] = useState(0); // Таймер для блокировки
  const [openSnackbar, setOpenSnackbar] = useState(false); // Для уведомления

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setOpenSnackbar(true); // Показываем уведомление
    setTimer(120); // Устанавливаем таймер на 2 минуты (120 секунд)
  };

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsSubmitted(false); // Разблокируем кнопку после истечения таймера
    }
    return () => clearInterval(interval);
  }, [timer]);

  // Создание темы с переопределенным свойством MuiInputBase
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "30px", // Установите высоту по вашему усмотрению
            marginBottom: "20px",
          },
          input: {
            // Стили для полной отмены эффекта автозаполнения
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset", // Прозрачный фон
              WebkitTextFillColor: "inherit", // Цвет текста как у родителя
              transition: "background-color 5000s ease-in-out 0s", // Убираем анимацию
            },
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "85vh" }}
    >
      <FormControl component="form" onSubmit={handleSubmit}>
        <Typography variant="h1" align="center">
          Сброс пароля
        </Typography>
        <Typography>Введите ваш Email:</Typography>
        <ThemeProvider theme={theme}>
          <TextField name="email" type="email" required />
        </ThemeProvider>
        <Button component={Link} to="/login">
          Назад
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitted} // Блокируем кнопку, если форма уже отправлена
        >
          {isSubmitted ? `Отправлено (${timer} сек.)` : "Сбросить пароль"}
        </Button>
      </FormControl>

      {/* Snackbar для показа уведомления */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Инструкцию по сбросу пароля отправили вам на указанную почту, если сообщение не пришло, проверьте папку Спам."
      />
    </Grid>
  );
}

export default PassReset;
