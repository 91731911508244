import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Checkbox, TextField } from "@mui/material";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDateRange,
  fetchCitationsWeThisWeek,
  fetchCitationsWeByPeriod,
  fetchCitationsUsThisWeek,
  fetchCitationsUsByPeriod,
  fetchAllExclusives,
  fetchAllExclusivesByPeriod,
  fetchMyExclusives,
  fetchMyExclusivesByPeriod,
  updateSchedule,
  downloadSheduleHoldingFile,
  downloadSheduleHoldingFileByPeriod,
  downloadSheduleUsHoldingFile,
  downloadSheduleUsHoldingFileByPeriod,
  uploadSheduleHoldingFile,
  uploadSheduleUsHoldingFile,
  postExclusiveMaterial,
} from "../../slices/holdingSlice";
import QuoteTableWe from "../QuoteTableWe";
import QuoteTableUs from "../QuoteTableUs";
import ExclusivesTable from "../ExclusivesTable";
import { getCurrentWeekDates } from "../../utils/dateUtils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Swal from 'sweetalert2';

function HoldingSchedule() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    citationsWe,
    citationsUs,
    exclusives,
    startDate,
    endDate,
    loadingCitationsWe,
    loadingCitationsUs,
    loadingExclusives,
  } = useSelector((state) => state.holding);
  const { user } = useSelector((state) => state.auth);
  const { sites } = useSelector((state) => state.site);

  const userId = user.user_id;

  const isWeActive = location.pathname === "/holding_shedule_we";
  const isUsActive = location.pathname === "/holding_shedule_us";

  // Состояние для хранения редактируемой ссылки и ее ID
  const [linkToEdit, setLinkToEdit] = useState({ id: null, url: "" });

  const [onlyMyExclusives, setOnlyMyExclusives] = useState(false);
  // Установка dateRange по умолчанию на текущую неделю
  // const [dateRange, setDateRangeState] = useState(getCurrentWeekDates());
  // const [isApplyButtonActive, setApplyButtonActive] = useState(false);

  const dateRange =
    startDate && endDate
      ? [new Date(startDate), new Date(endDate)]
      : getCurrentWeekDates();

  // Устанавливаем начальные даты в store при инициализации компонента
  useEffect(() => {
    const [newStartDate, newEndDate] = dateRange || [null, null];
    dispatch(setDateRange([newStartDate, newEndDate]));
  }, []);

  const fetchExclusives = () => {
    if (onlyMyExclusives) {
      if (startDate && endDate) {
        dispatch(fetchMyExclusivesByPeriod({ userId, startDate, endDate }));
      } else {
        dispatch(fetchMyExclusives(userId));
      }
    } else {
      if (startDate && endDate) {
        dispatch(fetchAllExclusivesByPeriod({ startDate, endDate }));
      } else {
        dispatch(fetchAllExclusives());
      }
    }
  };

  const fetchCitations = () => {
    if (isWeActive) {
      if (startDate && endDate) {
        dispatch(fetchCitationsWeByPeriod({ userId, startDate, endDate }));
      } else {
        dispatch(fetchCitationsWeThisWeek(userId));
      }
    } else if (isUsActive) {
      if (startDate && endDate) {
        dispatch(fetchCitationsUsByPeriod({ userId, startDate, endDate }));
      } else {
        dispatch(fetchCitationsUsThisWeek(userId));
      }
    }
  };

  const handleUploadCitations = async (event) => {
    const file = event.target.files[0];

    if (file && userId) {
      // Вызов загрузки файла в зависимости от флага
      if (isWeActive) {
        dispatch(uploadSheduleHoldingFile({ userId, file }));
      } else {
        dispatch(uploadSheduleUsHoldingFile({ userId, file }));
      }

      // Сбрасываем значение поля файла, чтобы обеспечить повторный вызов
      event.target.value = null;
    }
  };

  useEffect(() => {
    fetchExclusives();
    fetchCitations();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchCitations();
  }, [isWeActive]);

  useEffect(() => {
    fetchExclusives();
  }, [onlyMyExclusives]);

  const handleCheckboxChange = (event) => {
    setOnlyMyExclusives(event.target.checked);
  };

  const handleDateChange = (newDateRange) => {
    const [newStartDate, newEndDate] = newDateRange || [null, null];
    dispatch(setDateRange([newStartDate, newEndDate]));
  };

  // Функция для обновления ссылки в Redux и на сервере
  const handleBlur = async () => {
    if (linkToEdit.id) {
      // Отправляем PUT запрос с user_material_id и новыми данными
      await dispatch(
        updateSchedule({
          user_material_id: linkToEdit.id,
          data: { url: linkToEdit.url },
        })
      );
    }
  };

  const handleDownloadCitationsClick = () => {
    if (isWeActive) {
      if (startDate && endDate) {
        dispatch(
          downloadSheduleHoldingFileByPeriod({ userId, startDate, endDate })
        );
      } else {
        dispatch(downloadSheduleHoldingFile(userId));
      }
    } else if (isUsActive) {
      if (startDate && endDate) {
        dispatch(
          downloadSheduleUsHoldingFileByPeriod({ userId, startDate, endDate })
        );
      } else {
        dispatch(downloadSheduleUsHoldingFile(userId));
      }
    }
  };

  const pickerStyle = {
    marginBottom: "20px",
    marginTop: "10px",
    marginRight: "10px",
  };

  // Устанавливаем фиксированную высоту для всех ячеек
  const cellStyle = {
    padding: "4px", // Паддинг для ячеек
    height: "50px", // Фиксированная высота для всех ячеек
  };
  const [selectedSite, setSelectedSite] = useState("");
  const [exclusiveLink, setExclusiveLink] = useState("");

  console.log("selectedSite", selectedSite);
  console.log("exclusiveLink", exclusiveLink);

  const handleChange = (event) => {
    setSelectedSite(event.target.value);
  };

  // Function to handle saving the exclusive link
  const handleSaveExclusiveLink = () => {
    if (exclusiveLink && selectedSite) {
      console.log("Saving exclusive link:", exclusiveLink);
      console.log("Selected site ID:", selectedSite);
      
      // Call the postExclusiveMaterial method with site ID and URL
      dispatch(postExclusiveMaterial({ 
        sites_id: selectedSite, 
        url: exclusiveLink 
      }))
        .unwrap()
        .then(() => {
          // Show success message
          Swal.fire({
            title: 'Успешно!',
            text: 'Эксклюзив успешно сохранен',
            icon: 'success',
            confirmButtonText: 'ОК'
          });
          
          // Clear inputs after saving
          setExclusiveLink("");
          setSelectedSite("");
          
          // Manually refresh the exclusives list
          setTimeout(() => {
            if (onlyMyExclusives) {
              dispatch(fetchMyExclusives(userId));
            } else {
              dispatch(fetchAllExclusives());
            }
          }, 500);
        })
        .catch((error) => {
          // Show error message
          Swal.fire({
            title: 'Ошибка!',
            text: error || 'Произошла ошибка при сохранении эксклюзива',
            icon: 'error',
            confirmButtonText: 'ОК'
          });
        });
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">Расписание холдинга</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ display: "flex" }}>
          <Typography>
            <Link
              // className="colorPrimary"
              to="/holding_shedule_we"
              style={{ color: isWeActive ? "red" : "black" }}
            >
              Цитируем мы
            </Link>
          </Typography>
          <Typography>&nbsp; / &nbsp;</Typography>
          <Typography>
            <Link
              to="/holding_shedule_us"
              style={{ color: isUsActive ? "red" : "black" }}
            >
              Цитируют нас
            </Link>
          </Typography>
        </div>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <DateRangePicker
              style={pickerStyle}
              value={dateRange}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Расписание
            </Button>
            <Button
              component={Link}
              to="/"
              variant="contained"
              color="secondary"
              style={{ marginRight: "10px" }}
            >
              Статистика
            </Button>
          </div>
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Typography style={{ marginRight: "10px" }}>
              Только мои эксклюзивы
            </Typography>
            <Checkbox
              checked={onlyMyExclusives}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "Показать только мои эксклюзивы" }}
            />
            <Grid container></Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Левая часть */}
        <Grid container>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mr: 1, mb: 1, height: 40 }}
            onClick={handleDownloadCitationsClick}
          >
            Скачать
          </Button>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            sx={{ mr: 1, mb: 1, height: 40 }}
          >
            Загрузить
            <input type="file" hidden onChange={handleUploadCitations} />
          </Button>
        </Grid>

        {/* Правая часть (исправлено) */}
        <Grid container justifyContent="right">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl
              fullWidth
              sx={{
                mr: 1,
                minWidth: 150,
                "& .MuiInputBase-root": {
                  height: 40, // Делаем поле ввода той же высоты, что и кнопки
                  display: "flex",
                  alignItems: "center", // Выравниваем текст по центру
                },
                "& .MuiInputLabel-root": {
                  top: "-3px", // Корректируем положение label, если он немного смещается
                },
              }}
              InputProps={{
                sx: {
                  height: 40,
                  paddingY: 0, // Убираем лишние отступы
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Сайт</InputLabel>
              <Select
                labelId="site-select-label"
                id="site-select"
                value={selectedSite}
                label="Сайт"
                onChange={handleChange}
              >
                {sites &&
                  sites.map((site) => (
                    <MenuItem key={site.sites_id} value={site.sites_id}>
                      {site.site_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              id="outlined-textarea"
              label="Ссылка на эксклюзив"
              placeholder="Введите ссылку"
              multiline
              value={exclusiveLink}
              onChange={(e) => setExclusiveLink(e.target.value)}
              sx={{
                mr: 1,
                minWidth: 250,
                "& .MuiInputBase-root": {
                  height: 40, // Делаем поле ввода той же высоты, что и кнопки
                  display: "flex",
                  alignItems: "center", // Выравниваем текст по центру
                },
                "& .MuiInputLabel-root": {
                  top: "-3px", // Корректируем положение label, если он немного смещается
                },
              }}
              InputProps={{
                sx: {
                  height: 40,
                  paddingY: 0, // Убираем лишние отступы
                },
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: 40, minWidth: 125 }}
              onClick={handleSaveExclusiveLink}
              disabled={!selectedSite || !exclusiveLink}
            >
              Сохранить
            </Button>
          </div>
        </Grid>
      </div>

      {/* Контейнер для таблиц */}
      <Grid container spacing={1} style={{ marginTop: "10px" }}>
        {" "}
        {/* Уменьшение расстояния между колонками */}
        <Grid item xs={7}>
          {" "}
          {/* Изменение соотношения ширины */}
          <Typography
            variant="h6"
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Расписание
          </Typography>
          {
            <>
              {loadingCitationsWe ? (
                <div className="preloader">Загрузка...</div>
              ) : (
                <>
                  {isWeActive && (
                    <QuoteTableWe
                      data={citationsWe}
                      cellStyle={cellStyle}
                      onLinkEdit={setLinkToEdit}
                      linkToEdit={linkToEdit}
                      onLinkBlur={handleBlur}
                    />
                  )}
                </>
              )}
              {loadingCitationsUs ? (
                <div className="preloader">Загрузка...</div>
              ) : (
                <>
                  {isUsActive && (
                    <QuoteTableUs data={citationsUs} cellStyle={cellStyle} />
                  )}
                </>
              )}
            </>
          }
        </Grid>
        <Grid item xs={5}>
          {" "}
          {/* Изменение соотношения ширины */}
          <Typography
            variant="h6"
            style={{ marginBottom: "10px", fontWeight: "bold" }}
          >
            Эксклюзивы
          </Typography>
          {loadingExclusives ? (
            <div className="preloader">Загрузка...</div>
          ) : (
            <ExclusivesTable data={exclusives} cellStyle={cellStyle} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default HoldingSchedule;
