// src/components/QuoteTableUs.js
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

function QuoteTableUs({ data, cellStyle }) {
  return (
    <TableContainer>
      <Table size="big">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, width: "120px", verticalAlign: "top" }}>
              День недели и дата
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Название
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Домен
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Ссылка
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry, entryIndex) =>
            entry.data && Array.isArray(entry.data) ? (
              entry.data.map((dateEntry, dateIndex) => (
                dateEntry && dateEntry.data ? (
                  <React.Fragment key={`${dateEntry.date}-${entryIndex}-${dateIndex}`}>
                    <TableRow>
                      <TableCell
                        rowSpan={Object.keys(dateEntry.data).length}
                        align="center"
                        style={{ ...cellStyle }}
                      >
                        <div>
                          {dateEntry.date
                            ? new Date(dateEntry.date).toLocaleDateString("ru-RU", { weekday: "long" })
                            : "Нет даты"}
                        </div>
                        <div>
                          {dateEntry.date
                            ? new Date(dateEntry.date).toLocaleDateString("ru-RU", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              })
                            : ""}
                        </div>
                      </TableCell>
                      <TableCell align="left" style={{ ...cellStyle }}>
                        {Object.values(dateEntry.data)[0]?.name || "N/A"}
                      </TableCell>
                      <TableCell align="left" style={{ ...cellStyle }}>
                        {Object.values(dateEntry.data)[0]?.domen || "N/A"}
                      </TableCell>
                      <TableCell align="left" style={{ ...cellStyle }}>
                        {Object.values(dateEntry.data)[0]?.url || "N/A"}
                      </TableCell>
                    </TableRow>
                    {Object.values(dateEntry.data)
                      .slice(1)
                      .map((dataItem, itemIndex) => (
                        <TableRow
                          key={`${dateEntry.date}-${dataItem.domen}-${itemIndex}`}
                        >
                          <TableCell align="left" style={{ ...cellStyle }}>
                            {dataItem.name || "N/A"}
                          </TableCell>
                          <TableCell align="left" style={{ ...cellStyle }}>
                            {dataItem.domen || "N/A"}
                          </TableCell>
                          <TableCell align="left" style={{ ...cellStyle }}>
                            {dataItem.url || "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ) : null
              ))
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QuoteTableUs;
