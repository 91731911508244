// Функция для получения даты начала (понедельник) и конца (воскресенье) текущей недели
export function getCurrentWeekDates() {
    const today = new Date();
    const currentDay = today.getDay();
  
    const monday = new Date(today);
    monday.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Если воскресенье, смещение на понедельник
  
    const sunday = new Date(today);
    sunday.setDate(today.getDate() + (currentDay === 0 ? 0 : 7 - currentDay)); // Если не воскресенье, смещение до воскресенья
  
    return [monday, sunday];
  }
  