import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../utils/useAxios";

const initialSelectedSite = localStorage.getItem("selectedSite") || "";

const initialState = {
  sites: [],
  selectedSite: initialSelectedSite, // Инициализация из localStorage
  scheduleWe: [],                       // Состояние для расписания
  scheduleUs: [],
  exclusives: [],
  siteId: null,
  startDate: null,
  endDate: null,
  loading: false,
  error: null,
  loadingScheduleWe: false,
  errorScheduleWe: null,
  loadingScheduleUs: false,
  errorScheduleUs: null,
  loadingExclusives: false,
  errorExclusives: null,
};

// Асинхронные действия
// Вспомогательная функция для загрузки файла на сервер
const uploadFileToServer = async (url, file, rejectWithValue) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : 'Ошибка загрузки файла'
    );
  }
};

// Функция-обертка для createAsyncThunk
export const uploadSheduleFile = createAsyncThunk(
  'site/uploadSheduleFile',
  async ({ siteId, file }, { rejectWithValue }) => {
    const url = `${baseURL}/Shedule/filesUpload/${siteId}`;
    return await uploadFileToServer(url, file, rejectWithValue);
  }
);

export const uploadSheduleUsFile = createAsyncThunk(
  'site/uploadSheduleUsFile',
  async ({ siteId, file }, { rejectWithValue }) => {
    const url = `${baseURL}/SheduleUs/filesUpload/${siteId}`;
    return await uploadFileToServer(url, file, rejectWithValue);
  }
);

export const uploadExclusivesSitesFile = createAsyncThunk(
  'site/uploadExclusivesSitesFile',
  async ({ siteId, file }, { rejectWithValue }) => {
    const url = `${baseURL}/ExclusivesSites/filesUpload/${siteId}`;
    return await uploadFileToServer(url, file, rejectWithValue);
  }
);

// Вспомогательная функция для загрузки файла
const downloadFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Вспомогательная функция для обработки запроса и загрузки файла
const fetchAndDownloadFile = async (url, filename, rejectWithValue) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    downloadFile(response.data, filename);
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : 'Ошибка при скачивании файла'
    );
  }
};

// Функции-обертки для createAsyncThunk
export const downloadExclusiveFile = createAsyncThunk(
  'site/downloadExclusiveFile',
  async (siteId, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadExclusive/${siteId}`;
    const filename = `exclusive_site_id_${siteId}.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadExclusiveFileByPeriod = createAsyncThunk(
  'site/downloadExclusiveFileByPeriod',
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadExclusive/${siteId}/${startDate}/${endDate}/`;
    const filename = `exclusive_site_id_${siteId}_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadAllExclusiveFile = createAsyncThunk(
  'site/downloadAllExclusiveFile',
  async (_, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadExclusiveAll/`;
    const filename = `all_exclusives.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadAllExclusiveFileByPeriod = createAsyncThunk(
  'site/downloadAllExclusiveFileByPeriod',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadExclusiveAll/${startDate}/${endDate}/`;
    const filename = `all_exclusives_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleFile = createAsyncThunk(
  'site/downloadSheduleFile',
  async (siteId, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadShedule/${siteId}`;
    const filename = `shedule_site_id_${siteId}.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleFileByPeriod = createAsyncThunk(
  'site/downloadSheduleFileByPeriod',
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadShedule/${siteId}/${startDate}/${endDate}/`;
    const filename = `shedule_site_id_${siteId}_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleUsFile = createAsyncThunk(
  'site/downloadSheduleUsFile',
  async (siteId, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleUs/${siteId}`;
    const filename = `shedule_us_site_id_${siteId}.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleUsFileByPeriod = createAsyncThunk(
  'site/downloadSheduleUsFileByPeriod',
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleUs/${siteId}/${startDate}/${endDate}/`;
    const filename = `shedule_us_site_id_${siteId}_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const fetchSiteIdBySlug = createAsyncThunk(
  "site/fetchSiteIdBySlug",
  async (slug, { rejectWithValue, getState }) => {
    const { authTokens } = getState().auth;

    try {
      const response = await axios.get(`${baseURL}/api/get-site-id/${slug}/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      return response.data.site_id;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка авторизации");
    }
  }
);

export const updateSchedule = createAsyncThunk(
  "site/updateSchedule",
  async ({ user_material_id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${baseURL}/Scheduleupdate/${user_material_id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchSchedule = createAsyncThunk(
  "site/fetchSchedule",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/Schedule/${siteId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchScheduleByPeriod = createAsyncThunk(
  "site/fetchScheduleByPeriod",
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/Schedule/${siteId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchScheduleUS = createAsyncThunk(
  "site/fetchScheduleUS",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleUS/${siteId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchScheduleUSByPeriod = createAsyncThunk(
  "site/fetchScheduleUSByPeriod",
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleUS/${siteId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchMyExclusives = createAsyncThunk(
  "site/fetchMyExclusives",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerialSites/${siteId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchMyExclusivesByPeriod = createAsyncThunk(
  "site/fetchMyExclusivesByPeriod",
  async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerialSites/${siteId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchAllExclusives = createAsyncThunk(
  "site/fetchAllExclusives",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerial/all/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchAllExclusivesByPeriod = createAsyncThunk(
  "site/fetchAllExclusivesByPeriod",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerial/all/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
      localStorage.setItem("selectedSite", action.payload); // Сохранение в localStorage
    },
    clearSelectedSite: (state) => {
      state.selectedSite = "";
      localStorage.removeItem("selectedSite"); // Очистка localStorage
    },
    setDateRange: (state, action) => {
      if (action.payload.length === 2) {
        state.startDate = action.payload[0];
        state.endDate = action.payload[1];
      } else {
        state.startDate = null;
        state.endDate = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadExclusiveFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadExclusiveFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadExclusiveFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка при скачивании файла';
      })
      .addCase(downloadExclusiveFileByPeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadExclusiveFileByPeriod.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadExclusiveFileByPeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка при скачивании файла';
      })
      .addCase(downloadAllExclusiveFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadAllExclusiveFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadAllExclusiveFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка при скачивании файла';
      })
      .addCase(downloadAllExclusiveFileByPeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadAllExclusiveFileByPeriod.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadAllExclusiveFileByPeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка при скачивании файла';
      })
      .addCase(updateSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const { url } = action.meta.arg.data;
        const { user_material_id } = action.meta.arg;
    
        // Обновление ссылки в state.scheduleWe
        if (state.scheduleWe) {
            state.scheduleWe.forEach((citation) => {
                if (Array.isArray(citation.data)) {
                    citation.data.forEach((entry) => {
                        if (entry.data && typeof entry.data === 'object') {
                            const key = Object.keys(entry.data).find(
                                (key) => entry.data[key]?.user_material_id === user_material_id
                            );
    
                            // Только если ключ найден, обновляем url
                            if (key !== undefined) {
                                entry.data[key] = { ...entry.data[key], url };
                            }
                        }
                    });
                }
            });
        }
      })
      .addCase(fetchSiteIdBySlug.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSiteIdBySlug.fulfilled, (state, action) => {
        state.loading = false;
        state.siteId = action.payload;
      })
      .addCase(fetchSiteIdBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSchedule.pending, (state) => {
        state.loadingScheduleWe = true;
        state.errorScheduleWe = null;
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.scheduleWe = action.payload;
        state.loadingScheduleWe = false;
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.loadingScheduleWe = false;
        state.errorScheduleWe = action.payload;
      })
      .addCase(fetchScheduleByPeriod.pending, (state) => {
        state.loadingScheduleWe = true;
        state.errorScheduleWe = null;
      })
      .addCase(fetchScheduleByPeriod.fulfilled, (state, action) => {
        state.scheduleWe = action.payload;
        state.loadingScheduleWe = false;
      })
      .addCase(fetchScheduleByPeriod.rejected, (state, action) => {
        state.loadingScheduleWe = false;
        state.errorScheduleWe = action.payload;
      })
      .addCase(fetchScheduleUS.pending, (state) => {
        state.loadingScheduleUs = true;
        state.errorScheduleUs = null;
      })
      .addCase(fetchScheduleUS.fulfilled, (state, action) => {
        state.scheduleUs = action.payload;
        state.loadingScheduleUs = false;
      })
      .addCase(fetchScheduleUS.rejected, (state, action) => {
        state.loadingScheduleUs = false;
        state.errorScheduleUs = action.payload;
      })
      .addCase(fetchScheduleUSByPeriod.pending, (state) => {
        state.loadingScheduleUs = true;
        state.errorScheduleUs = null;
      })
      .addCase(fetchScheduleUSByPeriod.fulfilled, (state, action) => {
        state.scheduleUs = action.payload;
        state.loadingScheduleUs = false;
      })
      .addCase(fetchScheduleUSByPeriod.rejected, (state, action) => {
        state.loadingScheduleUs = false;
        state.errorScheduleUs = action.payload;
      })
      .addCase(fetchAllExclusives.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchAllExclusives.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchAllExclusives.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchAllExclusivesByPeriod.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchAllExclusivesByPeriod.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchAllExclusivesByPeriod.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchMyExclusives.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchMyExclusives.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchMyExclusives.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchMyExclusivesByPeriod.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchMyExclusivesByPeriod.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchMyExclusivesByPeriod.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(uploadSheduleFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSheduleFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadSheduleFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка загрузки файла';
      })
      .addCase(uploadSheduleUsFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSheduleUsFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadSheduleUsFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка загрузки файла';
      })
      .addCase(uploadExclusivesSitesFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadExclusivesSitesFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadExclusivesSitesFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка загрузки файла';
      });
  },
});

export const { setSites, setSelectedSite, clearSelectedSite, setDateRange } = siteSlice.actions;

export default siteSlice.reducer;
