import React from "react";
import { useParams } from "react-router-dom";

function SiteMain() {
  // Получаем параметр slug из URL
  const { slug } = useParams();
  const token = localStorage.getItem("authTokens");

  // Используем параметр slug для выполнения действий или запросов к API
  // Например, здесь можно использовать slug для загрузки данных для конкретного сайта

  return (
    <>
      {token !== null && (
        <div>
          <h1>Страница для сайта с slug: {slug}</h1>
        </div>
      )}
    </>
  );
}

export default SiteMain;
