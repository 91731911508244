import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { ReactComponent as Logo_with_descriptor } from "./Logo_with_descriptor.svg";
import { ReactComponent as Logo_without_text } from "./Logo_without_text.svg";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useAxios from "../utils/useAxios";
import { logoutUser, fetchDebtMaterials } from '../slices/authSlice';
import { setSites, setSelectedSite, clearSelectedSite } from "../slices/siteSlice";

function Navbar() {
  const { user, userDetails, debtMaterialsSitesIds } = useSelector((state) => state.auth);
  const { sites, selectedSite } = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const axiosInstance = useAxios();
  const [isSitesFetched, setIsSitesFetched] = useState(false);

  useEffect(() => {
    const fetchUserSites = async () => {
      try {
        const response = await axiosInstance.get('/get-user-sites/');
        dispatch(setSites(response.data));
        setIsSitesFetched(true); // Обновляем состояние после успешного вызова
      } catch (error) {
        console.error('Ошибка при загрузке сайтов:', error);
      }
    };
  
    if (userDetails && !isSitesFetched) {
      fetchUserSites(); // Проверяем, нужно ли вызывать снова
      dispatch(fetchDebtMaterials());
    } 
  }, [userDetails, dispatch, axiosInstance, isSitesFetched, debtMaterialsSitesIds]);

  const handleFetchDebtMaterials = () => {
    dispatch(fetchDebtMaterials());
  };

  const handleChange = (event) => {
    const siteValue = event.target.value;
    dispatch(setSelectedSite(siteValue));
    history.push(`/site/${siteValue}/shedule_we`);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(clearSelectedSite());
    history.push('/login');
  };

  const handleHoldingClick = () => {
    dispatch(clearSelectedSite()); // Очищаем выбранный сайт
    history.push('/'); // Перенаправляем на главную страницу холдинга
  };

  return (
    <>
      {user !== null && (
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{ paddingTop: "25px" }}
        >
          <Grid item md={2}>
            <>
              <Logo_without_text style={{ width: "28px", height: "30px" }} />
              <Logo_with_descriptor style={{ width: "80px", height: "29px" }} />
            </>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Представление
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSite}
                label="Представление"
                onChange={handleChange}
              >
                {sites.map((site_dic, index) => (
                  <MenuItem key={index} value={site_dic.slug}>
                    <Link
                      to={`/site/${site_dic.slug}`}
                      style={{ width: "100%" }}
                    >
                      {site_dic.site_name}
                    </Link>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <Link 
            to="#" 
            className='colorPrimary'
            onClick={handleFetchDebtMaterials}
            >Долг материалов {debtMaterialsSitesIds}</Link>
          </Grid>
          <Grid item md={1}>
            <Link to="/" onClick={handleHoldingClick}>Холдинг</Link>
          </Grid>
          {location.pathname.includes("/site/") ? (
            <>
              <Grid item md={1}>
                <Link
                  to={`/site/${location.pathname.split("/")[2]}/shedule_we`}
                  className={
                    location.pathname.includes("shedule_we") ? "colorPrimary" : ""
                  }
                >
                  Расписание
                </Link>
              </Grid>
              <Grid item md={1}>
                <Link
                  to={`/site/${location.pathname.split("/")[2]}/settings`}
                  className={
                    location.pathname.includes("settings") ? "colorPrimary" : ""
                  }
                >
                  Настройки
                </Link>
              </Grid>
            </>
          ) : (
            <Grid item md={1}>
              <Link
                to="/holding-setting"
                className={
                  location.pathname.includes("holding-setting")
                    ? "colorPrimary"
                    : ""
                }
              >
                Настройки
              </Link>
            </Grid>
          )}
          <Grid item md={0}>
            <Typography>{ userDetails?.name }</Typography>
          </Grid>
          <Grid item md={1}>
                <Link
                  to={`/support`}
                  className={
                    location.pathname.includes("support")
                      ? "colorPrimary"
                      : ""
                  }
                >
                  Поддержка
                </Link>
              </Grid>
          <Grid item md={1}>
            <Link to='#' onClick={handleLogout}>Выход</Link>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Navbar;
