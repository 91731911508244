import { Grid, TableCell, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import useAxios from "../../utils/useAxios";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";

function SiteSettings() {
  // Получаем параметр slug из URL
  const { slug } = useParams();
  const token = localStorage.getItem("authTokens");
  const axiosInstance = useAxios();

  const [site_id, setSiteId] = useState();
  const [siteName, setSiteName] = useState();
  const [siteSlug, setSiteSlug] = useState();

  const [mNum, setMNum] = useState();
  const [influence, setInfluence] = useState();
  const [mlgObject, setMlgObject] = useState();

  const [mlgLevels, setMlgLevels] = useState([]);
  const [mlgRegions, setMlgRegions] = useState([]);
  const [mlgCategories, setMlgCategories] = useState([]);

  const [rssSite, setRssSite] = useState('');

  useEffect(() => {
    fetchSiteSetting();
  }, []);

  const fetchSiteSetting = async () => {
    try {
      const response = await axiosInstance.get(`/site/setting/${slug}/`);
      setSiteId(response.data.site_id);
      setSiteName(response.data.site_name);
      setSiteSlug(response.data.slug);
      setMNum(response.data.site_m_num);
      setMlgObject(response.data.mlg_object);
      setMlgLevels(response.data.mlg_level);
      setMlgRegions(response.data.mlg_region);
      setInfluence(response.data.influence.influence);
      setMlgCategories(response.data.mlg_category);
      setRssSite(response.data.rss);
      console.log("response", response);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleMNumChange = (e) => {
    setMNum(e.target.value);
  };

  const handleRSSChange = (e) => {
    setRssSite(e.target.value);
  };

  const handleSiteChange = async (e) => {
    const m_num = e.target.value;
    try {
      await axiosInstance.put(`/sites/${site_id}/`, {
        site_m_num: m_num,
        slug: siteSlug,
        site_name: siteName,
        rss: rssSite,
      });
    } catch (error) {
      console.error("Error updating site:", error);
    }
  };

  const handleMlgObject = async (e, field) => {
    // Проверяем, загружен ли объект mlgObject
    if (!mlgObject) {
      console.error("MlgObject is not loaded yet");
      return;
    }

    const updatedMlgObj = { ...mlgObject };
    if (typeof updatedMlgObj[field] === "boolean") {
      updatedMlgObj[field] = e.target.checked;
    } else {
      updatedMlgObj[field] = e.target.value;
    }
    // console.log(updatedMlgObj)
    try {
      await axiosInstance.put(
        `/mlgobject/${mlgObject.mlg_object_id}/`,
        updatedMlgObj
      );
      console.log(updatedMlgObj);
      fetchSiteSetting();
    } catch (error) {
      console.error("Error updating site:", error);
    }
  };

  // Используем параметр slug для выполнения действий или запросов к API
  // Например, здесь можно использовать slug для загрузки данных для конкретного сайта
  const theme = createTheme({
    palette: {
      primary: {
        main: "#EF273D",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "39px", // Установите высоту по вашему усмотрению
          },
        },
      },
    },
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  return (
    <>
      {token !== null && (
        <>
          <Typography variant="h1">Настройки сайта {siteName}</Typography>
          <TextField
                label="RSS-сайта"
                value={rssSite}
                onChange={handleRSSChange}
                style={{ marginRight: "10px", width:'300px' }}
              />
          <Typography variant="h2">Карточка вашего СМИ</Typography>
          <ThemeProvider theme={theme}>
            <TableContainer style={{ height: 140 }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Впишете количество материалов, которое вы готовы писать в
                      течении недели
                    </TableCell>
                    <TableCell align="center">Ваш индекс влияния</TableCell>
                    <TableCell align="center">
                      Сумма ИЦ, которые вы будете получать и отдавать в неделю
                      *предполагаемые значения
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>
                    <TextField
                      name="m_num"
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      value={mNum}
                      onChange={handleMNumChange}  // Обновляет состояние при каждом изменении
                      onBlur={handleSiteChange}    // Отправляет запрос при покидании поля ввода
                      inputProps={{ min: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography align="center">{influence}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">
                      {(mNum * influence).toFixed(3)}
                    </Typography>
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer style={{ height: 100 }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Категория СМИ</TableCell>
                    <TableCell align="left">Уровень СМИ</TableCell>
                    <TableCell align="left">Регион</TableCell>
                    <TableCell align="left">Новостной агрегатор:</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>
                    <Select
                      value={mlgObject ? mlgObject.mlg_category_site : []}
                      onChange={(e) => handleMlgObject(e, "mlg_category_site")}
                      input={<OutlinedInput label="mlg_category_site" />}
                      MenuProps={MenuProps}
                      style={{ maxWidth: "150px" }}
                    >
                      {mlgCategories.map((mlgCategory, index) => (
                        <MenuItem key={mlgCategory.id} value={mlgCategory.id}>
                          {mlgCategory.mlg_category}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={mlgObject ? mlgObject.mlg_level_site : []}
                      onChange={(e) => handleMlgObject(e, "mlg_level_site")}
                      input={<OutlinedInput label="mlg_level_site" />}
                      MenuProps={MenuProps}
                      style={{ maxWidth: "150px" }}
                    >
                      {mlgLevels.map((mlgLevel, index) => (
                        <MenuItem key={mlgLevel.id} value={mlgLevel.id}>
                          {mlgLevel.mlg_level}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={mlgObject ? mlgObject.mlg_region_site : []}
                      onChange={(e) => handleMlgObject(e, "mlg_region_site")}
                      input={<OutlinedInput label="mlg_region_site" />}
                      MenuProps={MenuProps}
                      style={{ maxWidth: "150px" }}
                    >
                      {mlgRegions.map((mlgRegion, index) => (
                        <MenuItem key={mlgRegion.id} value={mlgRegion.id}>
                          {mlgRegion.mlg_region}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={mlgObject ? mlgObject.mlg_is_aggregator : []}
                      onChange={(e) => handleMlgObject(e, "mlg_is_aggregator")}
                    />
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
          <Typography variant="h2">Настройки расписания</Typography>
          <Grid container justifyContent="space-between">
            <Grid item md={5}>
              <Typography>Фильтр по СМИ. Цитируют Нас</Typography>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Категория СМИ</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.donor_category : []}
                          onChange={(e) => handleMlgObject(e, "donor_category")}
                          input={<OutlinedInput label="donor_category" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgCategories.map((mlgCategory, index) => (
                            <MenuItem
                              key={mlgCategory.id}
                              value={mlgCategory.id}
                            >
                              {mlgCategory.mlg_category}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Уровень СМИ</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.donor_level : []}
                          onChange={(e) => handleMlgObject(e, "donor_level")}
                          input={<OutlinedInput label="donor_level" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgLevels.map((mlgLevel, index) => (
                            <MenuItem key={mlgLevel.id} value={mlgLevel.id}>
                              {mlgLevel.mlg_level}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Регион СМИ (исключить)</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.donor_region : []}
                          onChange={(e) => handleMlgObject(e, "donor_region")}
                          input={<OutlinedInput label="donor_region" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgRegions.map((mlgRegion, index) => (
                            <MenuItem key={mlgRegion.id} value={mlgRegion.id}>
                              {mlgRegion.mlg_region}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Новостные агрегаторы</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            mlgObject ? mlgObject.donor_is_aggregator : []
                          }
                          onChange={(e) =>
                            handleMlgObject(e, "donor_is_aggregator")
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={5}>
              <Typography>Фильтр по СМИ. Цитируем Мы</Typography>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Категория СМИ</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.recipient_category : []}
                          onChange={(e) =>
                            handleMlgObject(e, "recipient_category")
                          }
                          input={<OutlinedInput label="recipient_category" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgCategories.map((mlgCategory, index) => (
                            <MenuItem
                              key={mlgCategory.id}
                              value={mlgCategory.id}
                            >
                              {mlgCategory.mlg_category}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Уровень СМИ</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.recipient_level : []}
                          onChange={(e) =>
                            handleMlgObject(e, "recipient_level")
                          }
                          input={<OutlinedInput label="recipient_level" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgLevels.map((mlgLevel, index) => (
                            <MenuItem key={mlgLevel.id} value={mlgLevel.id}>
                              {mlgLevel.mlg_level}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Регион СМИ (исключить)</TableCell>
                      <TableCell>
                        <Select
                          multiple
                          value={mlgObject ? mlgObject.recipient_region : []}
                          onChange={(e) =>
                            handleMlgObject(e, "recipient_region")
                          }
                          input={<OutlinedInput label="recipient_region" />}
                          MenuProps={MenuProps}
                          style={{ maxWidth: "150px" }}
                        >
                          {mlgRegions.map((mlgRegion, index) => (
                            <MenuItem key={mlgRegion.id} value={mlgRegion.id}>
                              {mlgRegion.mlg_region}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Новостные агрегаторы</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            mlgObject ? mlgObject.recipient_is_aggregator : []
                          }
                          onChange={(e) =>
                            handleMlgObject(e, "recipient_is_aggregator")
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <hr
            style={{ marginTop: "30px", marginBottom: "10px", opacity: "0.2" }}
          ></hr>
          <Typography style={{color:'#EF273D'}}>Для изменения индекса влияния обратитесь к администратору ресурса</Typography>
        </>
      )}
    </>
  );
}
export default SiteSettings;
