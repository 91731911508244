// src/components/QuoteTableWe.js
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField } from "@mui/material";

function QuoteTableWe({ data, cellStyle, onLinkEdit, linkToEdit, onLinkBlur }) {
  if (!data || !Array.isArray(data)) {
    console.warn("Data is undefined or not an array in QuoteTableWe:", data);
    return null;
  }
  return (
    <TableContainer>
      <Table size="big">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, width: "120px", verticalAlign: "top" }}>
              День недели и дата
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Название
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Домен
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", ...cellStyle, verticalAlign: "top" }}>
              Ссылка
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && Array.isArray(data) ? (
            data.map((entry) =>
              entry.data && Array.isArray(entry.data) ? (
                entry.data.map((dateEntry) =>
                  dateEntry && dateEntry.data ? (
                    <React.Fragment key={dateEntry.date || Math.random()}>
                      <TableRow>
                        <TableCell
                          rowSpan={Object.keys(dateEntry.data).length}
                          align="center"
                          style={{ ...cellStyle }}
                        >
                          <div>
                            {dateEntry.date
                              ? new Date(dateEntry.date).toLocaleDateString("ru-RU", { weekday: "long" })
                              : "Нет даты"}
                          </div>
                          <div>
                            {dateEntry.date
                              ? new Date(dateEntry.date).toLocaleDateString("ru-RU", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                })
                              : ""}
                          </div>
                        </TableCell>
                        <TableCell align="left" style={{ ...cellStyle }}>
                          {Object.values(dateEntry.data)[0]?.name || "N/A"}
                        </TableCell>
                        <TableCell align="left" style={{ ...cellStyle }}>
                          {Object.values(dateEntry.data)[0]?.domen || "N/A"}
                        </TableCell>
                        <TableCell align="center" style={{ ...cellStyle }}>
                          <TextField
                            value={
                              linkToEdit.id === Object.values(dateEntry.data)[0]?.user_material_id
                                ? linkToEdit.url
                                : Object.values(dateEntry.data)[0]?.url
                            }
                            onClick={() =>
                              onLinkEdit({
                                id: Object.values(dateEntry.data)[0]?.user_material_id,
                                url: Object.values(dateEntry.data)[0]?.url,
                              })
                            }
                            onChange={(e) => {
                              if (linkToEdit.id === Object.values(dateEntry.data)[0]?.user_material_id) {
                                onLinkEdit((prev) => ({
                                  ...prev,
                                  url: e.target.value,
                                }));
                              }
                            }}
                            onBlur={() => {
                              onLinkBlur(
                                Object.values(dateEntry.data)[0]?.user_material_id,
                                Object.values(dateEntry.data)[0]?.url
                              );
                            }}
                            fullWidth
                            variant="outlined"
                            size="small"
                            style={{ width: "85%", marginRight: "5px" }}
                          />
                        </TableCell>
                      </TableRow>
                      {Object.values(dateEntry.data)
                        .slice(1)
                        .map((dataItem) => (
                          <TableRow key={dataItem.user_material_id || Math.random()}>
                            <TableCell align="left" style={{ ...cellStyle }}>
                              {dataItem.name || "N/A"}
                            </TableCell>
                            <TableCell align="left" style={{ ...cellStyle }}>
                              {dataItem.domen || "N/A"}
                            </TableCell>
                            <TableCell align="center" style={{ ...cellStyle }}>
                              <TextField
                                value={linkToEdit.id === dataItem.user_material_id ? linkToEdit.url : dataItem.url}
                                onClick={() =>
                                  onLinkEdit({
                                    id: dataItem.user_material_id,
                                    url: dataItem.url,
                                  })
                                }
                                onChange={(e) => {
                                  if (linkToEdit.id === dataItem.user_material_id) {
                                    onLinkEdit((prev) => ({
                                      ...prev,
                                      url: e.target.value,
                                    }));
                                  }
                                }}
                                onBlur={() => {
                                  onLinkBlur(dataItem.user_material_id, dataItem.url);
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{ width: "85%", marginRight: "5px" }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ) : null
                )
              ) : null
            )
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                Нет данных
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QuoteTableWe;
