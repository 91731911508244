import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Компонент для отрисовки таблицы эксклюзивов
const ExclusivesTable = ({ data, cellStyle }) => {
  // Ensure data is an array before rendering
  const exclusivesData = Array.isArray(data) ? data : [];

  return (
    <TableContainer>
    <Table size="big">
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            style={{
              fontWeight: "bold",
              ...cellStyle,
              verticalAlign: "top",
            }}
          >
            Дата
          </TableCell>
          <TableCell
            align="left"
            style={{
              fontWeight: "bold",
              ...cellStyle,
              verticalAlign: "top",
            }}
          >
            Домен
          </TableCell>
          <TableCell
            align="left"
            style={{
              fontWeight: "bold",
              ...cellStyle,
              verticalAlign: "top",
            }}
          >
            Ссылка
          </TableCell>
          <TableCell
            align="left"
            style={{
              fontWeight: "bold",
              ...cellStyle,
              verticalAlign: "top",
            }}
          >
            Город
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exclusivesData.map((exclusiveItem, index) => (
          <TableRow key={index}>
            <TableCell align="left" style={{ ...cellStyle }}>
              {exclusiveItem && exclusiveItem.date_upload 
                ? new Date(exclusiveItem.date_upload).toLocaleDateString("ru-RU")
                : "—"}
            </TableCell>
            <TableCell align="left" style={{ ...cellStyle }}>
              {exclusiveItem && exclusiveItem.domen || "—"}
            </TableCell>
            <TableCell align="left" style={{ ...cellStyle }}>
              {exclusiveItem && exclusiveItem.url ? (
                <a
                  href={exclusiveItem.url}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {typeof exclusiveItem.url === 'string' && exclusiveItem.url.length > 25
                    ? `${exclusiveItem.url.slice(0, 25)}...`
                    : exclusiveItem.url}
                </a>
              ) : "—"}
            </TableCell>
            <TableCell align="left" style={{ ...cellStyle }}>
              {exclusiveItem && exclusiveItem.region || "—"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
};

export default ExclusivesTable;
