import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

import Homepage from "./components/holding/HoldingMainPage";
import HoldingShedule  from "./components/holding/HoldingShedule";
import SupportPage  from "./Support.jsx";


import Registerpage from "./components/Registerpage";
import Loginpage from "./components/Loginpage";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
import PassReset from "./components/PassReset";
import PassNewPass from "./components/PassNewPass";



import Container from "@mui/material/Container";
import HoldingeSettings from "./components/holding/HoldingeSettings";
import SiteMain from "./components/site/SiteMain";
import SiteSettings from "./components/site/SiteSettings";
import Shedule from "./components/site/Shedule";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import './App.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF273D",
    },
    secondary: {
      main: "#ffffff",
    }
  },
  components:{
    MuiTypography:{
      styleOverrides:{
        h1: {
          fontSize: '40px',
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '500',
        },
        h2: {
          fontSize: '32px',
          paddingTop: '25px',
          paddingBottom: '15px',
        },
      },
    },
  },
  
});


function App() {
  return (
    <ThemeProvider theme={theme}>
    <Container maxWidth={false} sx={{ paddingLeft: "16px", paddingRight: "16px", margin: "0", maxWidth: "100%" }}>
      <Router>
        <Navbar />
        <Switch>
          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <Route component={Loginpage} path="/login" />
          <Route component={SupportPage} path="/support" />
          <Route component={Registerpage} path="/register" exact />
          <Route component={PassReset} path="/password_reset" exact />
          <Route component={PassNewPass} path="/password_confirm" exact />
          <PrivateRoute component={Homepage} path="/" exact />
          <PrivateRoute component={HoldingShedule} path="/holding_shedule_we" exact />
          <PrivateRoute component={HoldingShedule} path="/holding_shedule_us" exact />
          <PrivateRoute component={HoldingeSettings} path="/holding-setting" exact />
          <PrivateRoute component={SiteMain} path="/site/:slug" exact />
          <PrivateRoute component={SiteSettings} path="/site/:slug/settings" exact />
          <PrivateRoute component={Shedule} path="/site/:slug/shedule_we" exact />
          <PrivateRoute component={Shedule} path="/site/:slug/shedule_us" exact />
        </Switch>
      </Router>
    </Container>
    </ThemeProvider>

  );
}

export default App;
