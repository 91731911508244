import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, TableCell, Typography, Button } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DateRangePicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatisticsByPeriod, fetchStatisticsThisWeek, setDateRange } from "../../slices/holdingSlice";
import "rsuite/dist/rsuite-no-reset.min.css";
import { getCurrentWeekDates } from "../../utils/dateUtils";

function HoldingMainPage() {
  const dispatch = useDispatch();
  const { statistics, startDate, endDate } = useSelector((state) => state.holding);

  // const [dateRange, setDateRangeState] = useState(getCurrentWeekDates());
  const { user } = useSelector((state) => state.auth);
  // const [isApplyButtonActive, setApplyButtonActive] = useState(false);
  const userId = user.user_id;

  const dateRange = startDate && endDate ? [new Date(startDate), new Date(endDate)] : getCurrentWeekDates();

  useEffect(() => {
    const [newStartDate, newEndDate] = dateRange || [null, null];
    dispatch(setDateRange([newStartDate, newEndDate]));
  }, []);

  const fetchStatistics = () => {
    if (startDate && endDate) {
      dispatch(fetchStatisticsByPeriod({ userId, startDate, endDate }));
    } else {
      dispatch(fetchStatisticsThisWeek(userId));
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, [startDate, endDate]);

  const handleDateChange = (newDateRange) => {
    const [newStartDate, newEndDate] = newDateRange || [null, null];
    dispatch(setDateRange([newStartDate, newEndDate]));
  };


  const pickerStyle = {
    marginBottom: "20px",
    marginTop: "10px",
    marginRight: "10px",
  };

  // Calculate totals for the "Цитируют нас" and "Цитируем мы" columns
  const calculateTotals = () => {
    return statistics.reduce(
      (totals, site) => {
        const schedule = Array.isArray(site.schedule) ? site.schedule : [];
        const scheduleUs = Array.isArray(site.scheduleUs) ? site.scheduleUs : [];

        scheduleUs.forEach((entry) => {
          totals.totalMaterialsUs += entry.materials || 0;
          totals.totalCitationIndexUs += entry.index_citation || 0;
        });

        schedule.forEach((entry) => {
          totals.totalMaterials += entry.materials || 0;
          totals.totalCitationIndex += entry.index_citation || 0;
        });

        return totals;
      },
      { totalMaterialsUs: 0, totalCitationIndexUs: 0, totalMaterials: 0, totalCitationIndex: 0 }
    );
  };

  const totals = calculateTotals();

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">Главная страница холдинга</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <DateRangePicker
              style={pickerStyle}
              value={dateRange}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ display: "flex" }}>
            <Button
              component={Link}
              to="/holding_shedule_we"
              variant="contained"
              color="secondary"
              style={{ marginRight: "10px" }}
            >
              Расписание
            </Button>
            <Button variant="contained" color="primary">
              Статистика
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="big">
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} style={{ fontWeight: "bold" }}>
                    Название СМИ
                  </TableCell>
                  <TableCell colSpan={4} style={{ textAlign: "left", fontWeight: "bold" }}>
                    Цитируют нас
                  </TableCell>
                  <TableCell colSpan={4} style={{ textAlign: "left", fontWeight: "bold" }}>
                    Цитируем мы
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Название</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Влиятельность</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Кол-во материалов</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Индекс цитирования</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Название</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Влиятельность</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Кол-во материалов</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Индекс цитирования</TableCell>
                </TableRow>
                {/* Totals Row */}
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Итого</TableCell>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{Math.round(totals.totalMaterialsUs)}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{totals.totalCitationIndexUs.toFixed(3)}</TableCell>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{Math.round(totals.totalMaterials)}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{totals.totalCitationIndex.toFixed(3)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics.map((site) => {
                  const schedule = Array.isArray(site.schedule) ? site.schedule : [];
                  const scheduleUs = Array.isArray(site.scheduleUs) ? site.scheduleUs : [];
                  const maxRows = Math.max(schedule.length, scheduleUs.length) || 1;

                  return (
                    <React.Fragment key={site.sites_id}>
                      {[...Array(maxRows)].map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {rowIndex === 0 && (
                            <TableCell rowSpan={maxRows}>{site.site_name}</TableCell>
                          )}
                          {/* Цитируют нас */}
                          <TableCell>
                            {scheduleUs[rowIndex] ? scheduleUs[rowIndex].name : "-"}
                          </TableCell>
                          <TableCell>
                            {scheduleUs[rowIndex] ? scheduleUs[rowIndex].influence : "-"}
                          </TableCell>
                          <TableCell>
                            {scheduleUs[rowIndex] ? Math.round(scheduleUs[rowIndex].materials) : "-"}
                          </TableCell>
                          <TableCell>
                            {scheduleUs[rowIndex] ? scheduleUs[rowIndex].index_citation.toFixed(3) : "-"}
                          </TableCell>
                          {/* Цитируем мы */}
                          <TableCell>
                            {schedule[rowIndex] ? schedule[rowIndex].name : "-"}
                          </TableCell>
                          <TableCell>
                            {schedule[rowIndex] ? schedule[rowIndex].influence : "-"}
                          </TableCell>
                          <TableCell>
                            {schedule[rowIndex] ? Math.round(schedule[rowIndex].materials) : "-"}
                          </TableCell>
                          <TableCell>
                            {schedule[rowIndex] ? schedule[rowIndex].index_citation.toFixed(3) : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default HoldingMainPage;
