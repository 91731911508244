import React, { useState } from "react";
import { FormControl, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function PassNewPass() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      setError("Пароли не совпадают");
    } else {
      setError("");
      // Здесь можно добавить логику для отправки формы, например, запрос на сервер
      console.log("Пароли совпадают, отправка формы");
    }
  };

  // Создание темы с переопределенным свойством MuiInputBase
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "30px", // Установите высоту по вашему усмотрению
            marginBottom: "20px",
          },
          input: {
            // Стили для полной отмены эффекта автозаполнения
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset", // Прозрачный фон
              WebkitTextFillColor: "inherit", // Цвет текста как у родителя
              transition: "background-color 5000s ease-in-out 0s", // Убираем анимацию
            },
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "85vh" }}
    >
      <FormControl component="form" onSubmit={handleSubmit}>
        <Typography variant="h1" align="center">
          Сброс пароля
        </Typography>
        <Typography>Введите новый пароль:</Typography>
        <ThemeProvider theme={theme}>
          <TextField
            name="password1"
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
        </ThemeProvider>
        <Typography>Введите пароль повторно:</Typography>
        <ThemeProvider theme={theme}>
          <TextField
            name="password2"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
        </ThemeProvider>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button variant="contained" color="primary" type="submit">
          Вход
        </Button>
      </FormControl>
    </Grid>
  );
}

export default PassNewPass;
