// /src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import siteReducer from './slices/siteSlice';
import holdingReducer from './slices/holdingSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    site: siteReducer,
    holding: holdingReducer,
  },
});

export default store;
