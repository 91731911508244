import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Route {...rest}>
      {!user ? <Redirect to="/login" /> : children}
    </Route>
  );
};

export default PrivateRoute;
