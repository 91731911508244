import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import { baseURL } from "../utils/useAxios";

const initialState = {
  authTokens: localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null,
  user: localStorage.getItem("authTokens")
    ? jwt_decode(localStorage.getItem("authTokens"))
    : null,
  userDetails: null,
  loading: false,
  debtMaterialsSitesIds: null,
};

export const fetchUserDetails = createAsyncThunk(
  "auth/fetchUserDetails",
  async (user_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/api/customuser/${user_id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDebtMaterials = createAsyncThunk(
  "auth/fetchDebtMaterials",
  async (_, { getState, rejectWithValue }) => {
    const { userDetails } = getState().auth;

    if (!userDetails || !userDetails.id) {
      return rejectWithValue("Пользователь не авторизован или ID не найден");
    }

    try {
      const response = await axios.get(`${baseURL}/debtMaterials/${userDetails.id}`);
      
      // Извлекаем только первый sites_id из массива
      const sitesId = response.data.length > 0 ? response.data[0].count_materials : null;
      
      return sitesId; // Возвращаем только первое значение sites_id
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(baseURL + "/token/", {
        email,
        password,
      });
      const data = response.data;
      localStorage.setItem("authTokens", JSON.stringify(data));
      Swal.fire({
        title: "Login Successful",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return data;
    } catch (error) {
      Swal.fire({
        title: "Ошибка авторизации",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ email, password, name, surname, holding_name, tel }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}/register/`, {
        email, password, name, surname, holding_name, tel
      });

      // Логируем ответ сервера для подтверждения успешной регистрации
      console.log("Server response:", response);

      if (response.status === 201) {
        Swal.fire({
          title: "Регистрация успешна!",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }

    } catch (error) {
      // Логируем ошибку для диагностики
      console.error("Registration error:", error.response ? error.response.data : error.message);

      Swal.fire({
        title: "Ошибка при регистрации",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens: (state, action) => {
      state.authTokens = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.authTokens = null;
      state.user = null;
      state.userDetails = null;
      state.debtMaterialsSitesIds = null;
      localStorage.removeItem("authTokens");
      Swal.fire({
        title: "Вы вышли из системы",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // Логин
      .addCase(loginUser.fulfilled, (state, action) => {
        state.authTokens = action.payload;
        state.user = jwt_decode(action.payload.access);
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
      })
      
      // Регистрация
      .addCase(registerUser.fulfilled, (state, action) => {
        // Логика только для успешной регистрации.
        Swal.fire({
          title: "Регистрация прошла успешно!",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .addCase(registerUser.rejected, (state) => {
        state.loading = false;
      })

      // Получение деталей пользователя
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchDebtMaterials.fulfilled, (state, action) => {
        state.debtMaterialsSitesIds = action.payload;
        state.loading = false;
      })
      .addCase(fetchDebtMaterials.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDebtMaterials.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAuthTokens, setUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
