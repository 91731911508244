import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../utils/useAxios";

// Initial state for holding slice
const initialState = {
  citationsWe: [],
  citationsUs: [],
  exclusives: [],
  statistics: [],
  startDate: null,  // Дата начала периода
  endDate: null,    // Дата окончания периода
  loading: false,
  error: null,
  loadingCitationsWe: false,
  errorCitationsWe: null,
  loadingCitationsUs: false,
  errorCitationsUs: null,
  loadingExclusives: false,
  errorExclusives: null,
  loadingStatistics: false,
  errorStatistics: null,
};

// Async actions
// Вспомогательная функция для загрузки файла на сервер
const uploadFileToServer = async (url, file, rejectWithValue) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : 'Ошибка загрузки файла'
    );
  }
};

// Функция-обертка для createAsyncThunk
export const uploadSheduleHoldingFile = createAsyncThunk(
  'holding/uploadSheduleHoldingFile',
  async ({ userId, file }, { rejectWithValue }) => {
    const url = `${baseURL}/SheduleHolding/filesUpload/${userId}`;
    return await uploadFileToServer(url, file, rejectWithValue);
  }
);

export const uploadSheduleUsHoldingFile = createAsyncThunk(
  'holding/uploadSheduleUsHoldingFile',
  async ({ userId, file }, { rejectWithValue }) => {
    const url = `${baseURL}/SheduleUsHolding/filesUpload/${userId}`;
    return await uploadFileToServer(url, file, rejectWithValue);
  }
);

// Вспомогательная функция для загрузки файла
const downloadFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Вспомогательная функция для обработки запроса и загрузки файла
const fetchAndDownloadFile = async (url, filename, rejectWithValue) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    downloadFile(response.data, filename);
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : 'Ошибка при скачивании файла'
    );
  }
};

// Функции-обертки для createAsyncThunk
export const downloadSheduleHoldingFile = createAsyncThunk(
  'holding/downloadSheduleHoldingFile',
  async (userId, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleHolding/${userId}/`;
    const filename = `shedule_holding.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleHoldingFileByPeriod = createAsyncThunk(
  'holding/downloadSheduleHoldingFileByPeriod',
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleHolding/${userId}/${startDate}/${endDate}/`;
    const filename = `shedule_holding_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleUsHoldingFile = createAsyncThunk(
  'holding/downloadSheduleUsHoldingFile',
  async (userId, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleUsHolding/${userId}/`;
    const filename = `shedule_us_holding.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const downloadSheduleUsHoldingFileByPeriod = createAsyncThunk(
  'holding/downloadSheduleUsHoldingFileByPeriod',
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    const url = `${baseURL}/filesDownloadSheduleUsHolding/${userId}/${startDate}/${endDate}/`;
    const filename = `shedule_us_holding_by_period.xlsx`;
    return await fetchAndDownloadFile(url, filename, rejectWithValue);
  }
);

export const updateSchedule = createAsyncThunk(
  "holding/updateSchedule",
  async ({ user_material_id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${baseURL}/Scheduleupdate/${user_material_id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "Ошибка сервера");
    }
  }
);

export const fetchStatisticsThisWeek = createAsyncThunk(
  "holding/fetchStatisticsThisWeek",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/holdingStats/${userId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchStatisticsByPeriod = createAsyncThunk(
  "holding/fetchStatisticsByPeriod",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/holdingStats/${userId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchCitationsWeThisWeek = createAsyncThunk(
  "holding/fetchCitationsWeThisWeek",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleHolding/${userId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchCitationsWeByPeriod = createAsyncThunk(
  "holding/fetchCitationsWeByPeriod",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleHolding/${userId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchCitationsUsThisWeek = createAsyncThunk(
  "holding/fetchCitationsUsThisWeek",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleHoldingUS/${userId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchCitationsUsByPeriod = createAsyncThunk(
  "holding/fetchCitationsUsByPeriod",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/ScheduleHoldingUS/${userId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchMyExclusives = createAsyncThunk(
  "holding/fetchMyExclusives",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerialHolding/${userId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchMyExclusivesByPeriod = createAsyncThunk(
  "holding/fetchMyExclusivesByPeriod",
  async ({ userId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerialHolding/${userId}/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchAllExclusives = createAsyncThunk(
  "holding/fetchAllExclusives",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerial/all/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const fetchAllExclusivesByPeriod = createAsyncThunk(
  "holding/fetchAllExclusivesByPeriod",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/exclusivematerial/all/${startDate}/${endDate}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Holding slice
const holdingSlice = createSlice({
  name: "holding",
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      if (action.payload && action.payload[0] && action.payload[1]) {
        // Устанавливаем отдельные переменные для начала и конца периода
        state.startDate = action.payload[0].toISOString().split("T")[0];
        state.endDate = action.payload[1].toISOString().split("T")[0];
      } else {
        state.startDate = null;
        state.endDate = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const { url } = action.meta.arg.data;
        const { user_material_id } = action.meta.arg;
    
        // Обновление ссылки в state.citationsWe
        if (state.citationsWe) {
            state.citationsWe.forEach((citation) => {
                if (Array.isArray(citation.data)) {
                    citation.data.forEach((entry) => {
                        if (entry.data && typeof entry.data === 'object') {
                            const key = Object.keys(entry.data).find(
                                (key) => entry.data[key]?.user_material_id === user_material_id
                            );
    
                            // Только если ключ найден, обновляем url
                            if (key !== undefined) {
                                entry.data[key] = { ...entry.data[key], url };
                            }
                        }
                    });
                }
            });
        }
      })
      .addCase(fetchStatisticsThisWeek.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchStatisticsThisWeek.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.loadingStatistics = false;
      })
      .addCase(fetchStatisticsThisWeek.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.errorStatistics = action.payload;
      })
      .addCase(fetchStatisticsByPeriod.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchStatisticsByPeriod.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.loadingStatistics = false;
      })
      .addCase(fetchStatisticsByPeriod.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.errorStatistics = action.payload;
      })
      .addCase(fetchCitationsWeThisWeek.pending, (state) => {
        state.loadingCitationsWe = true;
        state.errorCitationsWe = null;
      })
      .addCase(fetchCitationsWeThisWeek.fulfilled, (state, action) => {
        state.citationsWe = action.payload;
        state.loadingCitationsWe = false;
      })
      .addCase(fetchCitationsWeThisWeek.rejected, (state, action) => {
        state.loadingCitationsWe = false;
        state.errorCitationsWe = action.payload;
      })
      .addCase(fetchCitationsWeByPeriod.pending, (state) => {
        state.loadingCitationsWe = true;
        state.errorCitationsWe = null;
      })
      .addCase(fetchCitationsWeByPeriod.fulfilled, (state, action) => {
        state.citationsWe = action.payload;
        state.loadingCitationsWe = false;
      })
      .addCase(fetchCitationsWeByPeriod.rejected, (state, action) => {
        state.loadingCitationsWe = false;
        state.errorCitationsWe = action.payload;
      })
      .addCase(fetchCitationsUsThisWeek.pending, (state) => {
        state.loadingCitationsUs = true;
        state.errorCitationsUs = null;
      })
      .addCase(fetchCitationsUsThisWeek.fulfilled, (state, action) => {
        state.citationsUs = action.payload;
        state.loadingCitationsUs = false;
      })
      .addCase(fetchCitationsUsThisWeek.rejected, (state, action) => {
        state.loadingCitationsUs = false;
        state.errorCitationsUs = action.payload;
      })
      .addCase(fetchCitationsUsByPeriod.pending, (state) => {
        state.loadingCitationsUs = true;
        state.errorCitationsUs = null;
      })
      .addCase(fetchCitationsUsByPeriod.fulfilled, (state, action) => {
        state.citationsUs = action.payload;
        state.loadingCitationsUs = false;
      })
      .addCase(fetchCitationsUsByPeriod.rejected, (state, action) => {
        state.loadingCitationsUs = false;
        state.errorCitationsUs = action.payload;
      })
      .addCase(fetchAllExclusives.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchAllExclusives.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchAllExclusives.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchAllExclusivesByPeriod.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchAllExclusivesByPeriod.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchAllExclusivesByPeriod.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchMyExclusives.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchMyExclusives.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchMyExclusives.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(fetchMyExclusivesByPeriod.pending, (state) => {
        state.loadingExclusives = true;
        state.errorExclusives = null;
      })
      .addCase(fetchMyExclusivesByPeriod.fulfilled, (state, action) => {
        state.exclusives = action.payload;
        state.loadingExclusives = false;
      })
      .addCase(fetchMyExclusivesByPeriod.rejected, (state, action) => {
        state.loadingExclusives = false;
        state.errorExclusives = action.payload;
      })
      .addCase(uploadSheduleHoldingFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSheduleHoldingFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadSheduleHoldingFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка загрузки файла';
      })
      .addCase(uploadSheduleUsHoldingFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSheduleUsHoldingFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadSheduleUsHoldingFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Ошибка загрузки файла';
      });
  },
});

export const { setDateRange } = holdingSlice.actions;

export default holdingSlice.reducer;
