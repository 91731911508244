import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const SupportPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        // backgroundColor: '#f0f4f8',
      }}
    >
      <Card sx={{ maxWidth: 500, padding: 4, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
            Служба поддержки
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Уважаемый пользователь!
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            В случае возникновения проблем и вопросов при работе с сервисом, Вы можете задать свой вопрос службе поддержки.
            Также для улучшения качества работы сервиса, Вы можете направлять выявленные ошибки, которые будут направлены техническим
            специалистам для устранения.
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Почта службы поддержки: <strong>birzha-tsitirovaniya@yandex.ru</strong>
          </Typography>

        </CardContent>
      </Card>
    </Box>
  );
};

export default SupportPage;
